import * as React from "react";
import { useForm } from "react-hook-form";
import { useState } from "react";
import Button from "@/components/buttons/Button";
import ApprovalsModal from "@/utils/ViewApprovalsData/ApprovalsModal";
import { toggleAllEmployees } from "@/utils/ViewApprovalsData/approvalsFormHelpers";

export const GenerateApprovalsDataTable = ({ approvalsData }) =>
{
	const { register, handleSubmit } = useForm();
	const [data, setData] = useState({});
	const [showConfirmation, setShowConfirmation] = React.useState(false)
	const [type, setType] = React.useState('approve')

	const approve = formData =>
	{
			setType('approve')
			setData(formData)
			setShowConfirmation(true)
	}

	const reject = formData =>
	{
			setType('reject')
			setData(formData)
			setShowConfirmation(true)
	}

	return (
		<>
			{showConfirmation && (
				<ApprovalsModal
					formData={data}
					setShowModal={setShowConfirmation}
					approvalType={type}
				/>
			)}
			<form className='w-full'>

				<div className="flex w-full justify-end">
					<Button theme='secondary' className="mb-md mr-xl text-xl" onClick={handleSubmit(approve)}>Approve</Button>
					<Button className="" onClick={handleSubmit(reject)}>Reject</Button>
				</div>

				<table className="table-auto w-full">
						<thead className='bg-grey-20 w-full content-center text-left font-bold'>
								<tr className='w-full'>
										<th scope="col" className="p-sm">
											<input
												className='mr-sm w-md h-md'
												type='checkbox'
												onClick={toggleAllEmployees}
											/>
											Employees
										</th>

										{Object.keys(approvalsData.selectionsData[0].fields).map((key) => {
											return (
												<th scope="col" className="p-sm">{key}</th>
											);
										})}
								</tr>
						</thead>
						<tbody>
						{approvalsData.selectionsData.map(({ selectionId, employeeName, orderValue, fields }) => (
								<tr className='p-sm'>
									<td className="p-sm">
											<input
												ref={register}
												id={`input-${selectionId}`}
												type='checkbox'
												value={selectionId}
												name={selectionId}
												className='mr-sm w-md h-md employee-approval-checkbox'
											/>
											{employeeName}
									</td>

									{Object.keys(fields).map((key) => {
										return (
											<td className="p-sm">{fields[key]}</td>
										);
									})}
								</tr>
						))}
						</tbody>
				</table>
			</form>
		</>
	)
}

